import AutoNumeric from "autonumeric/dist/autoNumeric.min"

console.log('HERE')

window.spinner_hide = function() {
    $('#ajax-spinner').hide();
}

window.spinner_show = function() {
    $('#ajax-spinner').css('display', 'flex');
}

window.loader_hide = function() {
    $('#ajax-file-loader').hide();
    $('#ajax-file-loader p').hide();
}

window.loader_show = function() {
    $('#ajax-file-loader').css('display', 'flex');
    setTimeout(function(){ $('#ajax-file-loader p').show(); }, 5000);
}

window.switch_fiat_currency = function(elem) {
    let currency = $(elem).val();
    if (currency == 'czk') {
        $('.source-currency-symbol').text(' Kč');
        $('.price-symbol').text(' Kč/1 BTC');
        $('.show-for-currency-czk').show();
        $('.show-for-currency-eur').hide();
        $('.change-text-for-currency').each(function() {
           $(this).text($(this).data('czk'));
        });
    }
    else if (currency == 'eur') {
        $('.source-currency-symbol').text(' €');
        $('.price-symbol').text(' €/1 BTC');
        $('.show-for-currency-eur').show();
        $('.show-for-currency-czk').hide();
        $('.change-text-for-currency').each(function() {
            $(this).text($(this).data('eur'));
        });
    }
}
window.switch_crypto_currency = function(elem) {
    let currency = $(elem).val();

}

window.switch_crypto_currency_split = function(elem) {
    let currency = $(elem).val();
    if (currency == '') {
        $('#crypto-currency-split').show();
        $('#crypto-currency-split').find('input').prop('disabled', false).prop('required', true);
    }
    else {
        $('#crypto-currency-split').hide();
        $('#crypto-currency-split').find('input').prop('disabled', true).prop('required', false);
    }
}

window.calculate_crypto_currency_split = function(elem, other_id) {
    let split = $(elem).val();
    split = Math.round(parseInt(split));
    if (split < 0) { split = 0 }
    if (split > 100) { split = 100 }
    split = split - (split % 5);

    $(elem).val(split);
    $('#' + other_id).val(100-split);
}

window.reload_fixed_discounts = function(elem, unselect = false) {
    let client_id = $('#client_id').val();
    let fiat_currency = $("input[name='saving_application[fiat_currency]']:checked").val();
    let target_amount = $('#saving_application_target_amount').val();
    if (unselect) {
        $('#saving_application_wants_fixed_discount option:selected').removeAttr("selected");
    }
    let wants_fixed_discount = $('#saving_application_wants_fixed_discount').val();
    let request_params = {
        "client_id": client_id,
        "target_amount": target_amount,
        "currency": fiat_currency,
        "selected_value": wants_fixed_discount
    };
    // console.log('RELOADING STARTING PAYMENT');
    // console.debug(request_params);
    spinner_show();
    $.post( "/saving/fixed_discount_options.html", request_params, function( data ) {
        $('#fixed_discount_options_wrapper').html($(data).find('#fixed_discount_options').html());
    }).fail(function( resp ) {
        console.debug(resp);
    }).always(function() {
        spinner_hide();
    });
}
window.reload_metal_discount = function(elem) {
    let client_id = $('#client_id').val();
    let fiat_currency = $("input[name='saving_application[fiat_currency]']:checked").val();
    let request_params = {
        "client_id": client_id,
        "currency": fiat_currency
    };
    spinner_show();
    $.post( "/saving/metal_discount_option.html", request_params, function( data ) {
        $('#metal_discount_option_wrapper').html($(data).find('#metal_discount_option').html());
    }).fail(function( resp ) {
        console.debug(resp);
    }).always(function() {
        spinner_hide();
    });
}
window.reload_starting_payment = function(elem, saving_uuid = false) {
    let client_id = $('#client_id').val();
    let fiat_currency;
    if (saving_uuid) {
        fiat_currency = $('#saving_application_fiat_currency').val();
    }
    else {
        fiat_currency = $("input[name='saving_application[fiat_currency]']:checked").val();
    }
    let target_amount = $('#saving_application_target_amount').val();
    let periodical_amount = $('#saving_application_periodical_payment_amount').val();
    let no_dealer_discount = $('#saving_application_no_dealer_discount').prop('checked');
    let uses_automatic_transfers = $('#saving_application_uses_automatic_transfers').prop('checked');
    let uses_voucher = $('#saving_application_uses_voucher').prop('checked');
    let voucher_code = $('#saving_application_voucher_code').val();
    let voucher_code_valid = $('#voucher_code_valid').val();
    let starting_payment_override = $('#saving_application_starting_payment_override').val();
    let wants_fixed_discount = $('#saving_application_wants_fixed_discount').val();
    let wants_metal_discount = $('#saving_application_wants_metal_discount').prop('checked');
    if (!target_amount) { return }

    spinner_show();
    if (uses_voucher && voucher_code) {

        let voucher_request_params = {
            "client_id": client_id,
            "voucher_code": voucher_code,
            "currency": fiat_currency,
            "target_amount": target_amount,
            "saving_uuid": saving_uuid ? saving_uuid : null
        };

        console.log('CHECKING SAVING VOUCHER CODE');
        console.debug(voucher_request_params);
        $.post("/saving/check_voucher.json", voucher_request_params, function (data) {
            console.log('CHECKING SAVING VOUCHER CODE RESPONSE');
            console.debug(data);
            update_voucher_feedback('#saving_application_voucher_code', data);
        }).fail(function (data) {
            console.log('CHECKING SAVING VOUCHER CODE FAIL');
            console.debug(data);
            update_voucher_feedback('#saving_application_voucher_code', false);
        });
    }

    let request_params = {
        "client_id": client_id,
        "target_amount": target_amount,
        "periodical_payment_amount": periodical_amount,
        "currency": fiat_currency,
        "no_dealer_discount": no_dealer_discount,
        "uses_automatic_transfers": uses_automatic_transfers,
        "wants_fixed_discount": wants_fixed_discount,
        "wants_metal_discount": wants_metal_discount
    };
    if (saving_uuid) {
        request_params.saving_uuid = saving_uuid;
    }
    if (starting_payment_override) {
        request_params.fixed_entry_fee = starting_payment_override;
    }
    if (uses_voucher && voucher_code) {
        request_params.voucher_code = voucher_code;
    }

    console.log('RELOADING STARTING PAYMENT');
    console.debug(request_params);
    spinner_show();
    $.post( "/saving/parameters.json", request_params, function( data ) {
        console.log('RELOADING STARTING PAYMENT RESPONSE');
        console.debug(data);
        $('#starting-payment-value').html(data['starting_payment_human']);
        $('#full-starting-payment-value').html(data['full_starting_payment_human']);
        $('#first-payment-value').html(data['first_payment_human']);
        if (data['dealer_discount_available']) {
            $('#starting_payment_discount_checkbox').show();
            $('#starting_payment_discount_value').html(data['starting_payment_discount_human']).show();
        }
        else {
            $('#starting_payment_discount_value').hide();
        }
    }).fail(function(resp) {
        console.log('RELOADING STARTING PAYMENT FAIL');
        console.debug(resp);
        $('#starting-payment-value').html(resp['responseJSON']['error'] + ' ' + resp['responseJSON']['reason']);
        $('#full-starting-payment-value').html('CHYBA!');
        $('#first-payment-value').html('CHYBA!');
    }).always(function() {
        spinner_hide();
    });
    return true;
}

window.toggle_fee_zone_note = function(elem) {
    let fiat_currency = $("input[name='saving_application[fiat_currency]']:checked").val();
    let val = parseInt($(elem).val().replace(' ', ''));

    if ((fiat_currency == 'czk' && ((val >= 400000 && val < 500000) || (val >= 900000 && val < 1000000))) ||
        (fiat_currency == 'eur' && ((val >= 16000 && val < 20000) || (val >= 36000 && val < 40000)))) {
        $('#fee-zone-note').css('display', 'block');
    }
    else {
        $('#fee-zone-note').css('display', 'none');
    }
}

window.check_order_voucher_code = function(elem) {
    let client_id       = $('#client_id').val();
    let uses_voucher    = $('#order_application_uses_voucher').prop('checked');
    let fiat_currency = $("input[name='order_application[fiat_currency]']:checked").val();
    let voucher_code    = $('#order_application_voucher_code').val();
    let fiat_amount = $('#order_application_fiat_amount').val()
    if (!uses_voucher || !voucher_code) { return }

    let request_params = {
        "client_id": client_id,
        "voucher_code": voucher_code,
        "currency": fiat_currency
    };
    if (fiat_amount) {
        request_params.fiat_amount = fiat_amount;
    }

    // console.log('CHECKING ORDER VOUCHER CODE');
    // console.debug(request_params);
    spinner_show();
    $.post( "/order/check_voucher.json", request_params, function( data ) {
        update_voucher_feedback('#order_application_voucher_code', data);
    }).fail(function( ) {
        update_voucher_feedback('#order_application_voucher_code', false);
    }).always(function() {
        spinner_hide();
    });
    return true;
}

function update_voucher_feedback(elemid, data) {
    $('.voucher-feedback').hide();
    if (data) {
        if (data.usable) {
            $('#voucher_code_valid').val(1);
            $(elemid).first()[0].setCustomValidity("");
            $('.voucher-feedback').hide();
            $('.voucher-feedback-valid').show();
        }
        else {
            $('#voucher_code_valid').val(0);
            $(elemid).first()[0].setCustomValidity(data.reasons.join('. '));
            $('.voucher-feedback').hide();
            $('#voucher-code-invalid-note').hide();
            $('#voucher-code-invalidity-reasons').html(data.reasons.join('<br>'));
            $('.voucher-feedback-invalid').show();
        }
    }
    else {
        $('#voucher_code_valid').val(0);
        $(elemid).first()[0].setCustomValidity($('#voucher-code-invalid-note').text());
        $('.voucher-feedback').hide();
        $('#voucher-code-invalid-note').show();
        $('#voucher-code-invalidity-reasons').html('').hide();
        $('.voucher-feedback-invalid').show();
    }
}

window.reload_crypto_estimate = function(elem) {
    // console.debug(elem.value);
    let crypto_currency = $("input[name='order_application[crypto_currency]']:checked").val();
    if (!crypto_currency) {
        crypto_currency = $('#crypto_currency').val();
    }
    let fiat_currency = $("input[name='order_application[fiat_currency]']:checked").val();
    let fiat_amount = $('#order_application_fiat_amount').val()
    if (!fiat_amount) { return }

    let request_params = {
        "fiat_amount": fiat_amount,
        "fiat_currency": fiat_currency,
        "crypto_currency": crypto_currency
    }

    spinner_show();
    $.post( "/order/estimate_value.json", request_params, function( data ) {
        $('#estimate-crypto-amount').html(data['amount_human']);
        $('#estimate-exchange-rate').html(data['exchange_rate_human']);
    }).fail(function() {
        $('#estimate-crypto-amount').html('CHYBA!');
    }).always(function() {
        spinner_hide();
    });
}

window.reload_btc_transfer_estimate = function(elem) {
    // console.debug(elem.value);
    let client_uuid     = $('#client_uuid').val();
    let product_uuid    = $('#overcharge_transfer_product_uuid').val();
    let product_kind    = $('#overcharge_transfer_product_uuid').find(':selected').data('product-kind');
    let fiat_currency = $("input[name='overcharge_transfer[fiat_currency]']:checked").val();
    let insert_amount   = $('#overcharge_transfer_product_uuid').find(':selected').data('insert-amount');
    let insert_currency = $('#overcharge_transfer_product_uuid').find(':selected').data('insert-currency');
    let maximum_amount = $('.currently-available-amount.show-for-currency-'+fiat_currency).data('balance');
    let amount          = $('#overcharge_transfer_fiat_amount').val();
    let n;

    n = parseInt(insert_amount);
    if (n) { amount = n }
    n = parseInt(maximum_amount);
    if (amount && n && n < amount) { amount = n }
    AutoNumeric.getAutoNumericElement('#overcharge_transfer_fiat_amount').set(amount);

    if (!product_uuid || !amount) { return }

    let request_params = {
        "fiat_currency": fiat_currency,
        "fiat_amount": amount,
        "product_uuid": product_uuid,
        "product_kind": product_kind
    }

    spinner_show();
    $.post( "/client/"+ client_uuid +"/overcharge_transfer/estimate_value.json", request_params, function( data ) {
        $('#estimate-crypto-amount').html(data['amount_human']);
        $('#estimate-exchange-rate').html(data['exchange_rate_human']);
    }).fail(function() {
        $('#estimate-crypto-amount').html('CHYBA!');
    }).always(function() {
        spinner_hide();
    });
}

window.reload_fiat_estimate = function(elemid) {
    let crypto_currency = $('#crypto_currency').val();
    let crypto_unit = $('#crypto_unit').val();
    $("input[name='overcharge_transfer[fiat_currency]']:checked").val();
    let fiat_currency;
    if ($("input:radio.switch-input:checked").length) {
        fiat_currency = $("input:radio.switch-input:checked").val();
    }
    else {
        fiat_currency = $('#fiat_currency').val();
    }
    let amount = $(elemid).val();
    if (!amount) { return }

    let params = {"crypto_amount": amount, "crypto_currency": crypto_currency, "crypto_unit": crypto_unit, "fiat_currency": fiat_currency};
    //console.debug(params);

    spinner_show();
    $.post( "/buyout/estimate_value.json", params, function( data ) {
        $('#estimate-fiat-amount').html(data['amount_human']);
    }).fail(function() {
        $('#estimate-fiat-amount').html('CHYBA!');
    }).always(function() {
        spinner_hide();
    });
}

window.buyout_all = function(elem) {
    // console.debug(elem.value);
    let pick_all = $('#buyout_application_buyout_all').prop('checked');
    if (pick_all) {
        let str = $('#currently-available-amount').text().trim();
        $('#buyout_application_crypto_amount')
            .val(str.substring(0, str.length - 4))
            .prop('disabled', true);
    }
    else {
        $('#buyout_application_crypto_amount').prop('disabled', false);
    }
}

window.withdraw_all = function(elem) {
    // console.debug(elem.value);
    let pick_all = $('#withdrawal_application_withdraw_all').prop('checked');
    if (pick_all) {
        let str = $('#currently-available-amount').text().trim();
        $('#withdrawal_application_crypto_amount')
            .val(str.substring(0, str.length - 4))
            .prop('disabled', true);
    }
    else {
        $('#withdrawal_application_crypto_amount').prop('disabled', false);
    }
}

window.overcharge_withdraw_all = function(elem) {
    // console.debug(elem.value);
    let pick_all = $('#overcharge_withdrawal_withdraw_all').prop('checked');
    let fiat_currency = $("input[name='overcharge_withdrawal[fiat_currency]']:checked").val();
    if (pick_all) {
        let amount = $('.currently-available-amount.show-for-currency-'+fiat_currency).data('balance');
        $('#overcharge_withdrawal_fiat_amount')
            .val(amount)
            .prop('disabled', true);
    }
    else {
        $('#overcharge_withdrawal_fiat_amount').prop('disabled', false);
    }
}

window.overcharge_transfer_all = function(elem) {
    // console.debug(elem.value);
    let pick_all = $('#overcharge_transfer_transfer_all').prop('checked');
    let fiat_currency = $("input[name='overcharge_transfer[fiat_currency]']:checked").val();
    if (pick_all) {
        let amount = $('.currently-available-amount.show-for-currency-'+fiat_currency).data('balance');
        $('#overcharge_transfer_fiat_amount')
            .val(amount)
            .prop('disabled', true);
    }
    else {
        $('#overcharge_transfer_fiat_amount').prop('disabled', false);
    }
}

window.update_payment_qr_code = function(amount_elem_id, vs_elem_id, currency_elem_id, qr_elem_id, account_elem_id, pil_elem_id) {
  let amount          = $("#" + amount_elem_id).val();
  let variable_symbol = $("#" + vs_elem_id).val();
  let currency        = $('input[name=' + currency_elem_id + ']:checked').val();

  if (!currency) { currency = 'czk' }
  if (!amount) { return }

  spinner_show();
  $.get( "/client/payment-qr-code.json", {"amount": amount, "variable_symbol": variable_symbol, "source_currency": currency}, function( data ) {
    console.debug(data);
    $("#" + pil_elem_id).html(data.payment_identifier_label);
    $("#" + account_elem_id).html(data.account_number);
    $("#" + qr_elem_id).html(data.qr_code);
  }).fail(function() {
    $("#" + account_elem_id).html("CHYBA!!!");
    $("#" + qr_elem_id).html("CHYBA!!!");
  }).always(function() {
    spinner_hide();
  });
}

window.reload_automatic_command_summary = function(elem) {
    let frequency_type = $('#automatic_command_form_frequency_type').val();
    $('.automatic-command-frequency-type-data').hide();
-   $('#automatic_command_frequency_' + frequency_type).show();

    let formData = $('#automatic-command-form').serialize();

    spinner_show();
    $.post( "/automatic_command/summary.json", formData, function( data ) {
        $('#automatic-command-summary-box').html(data['box']);
        $('#estimate-crypto-amount').html(data['crypto_amount_human']);
        $('#estimate-exchange-rate').html(data['exchange_rate_human']);
        $('#current-price').html(data['exchange_rate_human']);
    }).fail(function() {
        $('#automatic-command-summary-box').html('CHYBA!!!');
    }).always(function() {
        spinner_hide();
    });
}

window.replace_content = function(ident, url) {
    spinner_show();
    $.get( url, function( data ) {
        $(ident).html(data);
    }).always(function() {
        spinner_hide();
    });
}

window.radio_toggle_control = function(elem, target_id, value) {
    let current_val = $(elem).val();
    if (value == current_val) {
        $("#" + target_id).show();
        $("#" + target_id + ' input')
            .prop('required', true)
            .prop('disabled', false);
    }
    else {
        $("#" + target_id).hide();
        $("#" + target_id + ' input')
            .prop('required', false)
            .prop('disabled', true);
    }
}
window.checkbox_toggle_control = function(elem, target_id, target_id_inverse = null) {
    console.debug(elem);
    let is_checked = $(elem).prop('checked');

    if (is_checked) {
        $("#" + target_id).hide();
        $("#" + target_id + ' input')
            .prop('disabled', true);
        if (target_id_inverse) {
            $("#" + target_id_inverse).show();
            $("#" + target_id_inverse + ' input')
                .prop('disabled', false);
        }
    }
    else {
        $("#" + target_id).show();
        $("#" + target_id + ' input')
            .prop('disabled', false);
        if (target_id_inverse) {
            $("#" + target_id_inverse).hide();
            $("#" + target_id_inverse + ' input')
                .prop('disabled', true);
        }
    }
}

window.switch_payment_frequency = function(elem) {
    let current_val = $(elem).val();

    $(".periodical-payment-label").addClass('display-none');
    $("#periodical-payment-label-" + current_val).removeClass('display-none');
    $("#saving_application_periodical_payment_amount").attr(
        'placeholder',
        $("#periodical-payment-label-" + current_val).data('placeholder-value')
    );
}

window.send_upload_instructions = function(elem, phone) {
    let orig_text = $(elem).text();
    $(elem).text('Odesílám...').prop('disabled', true);
    $.get( "/client/send_upload_instructions", {"by_phone": phone, "identification_type": "remote"}, function( data ) {
        $('#remote-identification-documents').html(data);
    }).fail(function() {
        $(elem).after('Chyba!');
        $(elem).text(orig_text).prop('disabled', false);
    });
}

window.refresh_document_form = function(elem, phone) {
    let orig_text = $(elem).text();
    $(elem).text('Odesílám...').prop('disabled', true);
    $.get( "/client/refresh_document_form", {"by_phone": phone, "identification_type": "remote"}, function( data ) {
        $('#remote-identification-documents').html(data);
    }).fail(function() {
        $(elem).after('Chyba!');
        $(elem).text(orig_text).prop('disabled', false);
    });
}

window.init_expiration_countdown = function(elemId) {
    const iid = setInterval(run_expiration_countdown, 1000)

    function pretty_time_string(num) {
        return ( num < 10 ? "0" : "" ) + num;
    }
    function run_expiration_countdown() {
        console.debug(elemId);
        console.debug($(elemId));
        console.debug($(elemId).first());
        let expiresIn = $(elemId).data('expires-in');
        console.debug(expiresIn);
        expiresIn = expiresIn - 1;
        if (expiresIn < 0) { expiresIn = 0; }
        $(elemId).data('expires-in', expiresIn);
        if (expiresIn <= 0) {
            clearInterval(iid);
            $('#expiration_countdown_row').hide();
            $('#expiration_note_row').show();
        }

        var minutes = Math.floor(expiresIn / 60);
        expiresIn = expiresIn % 60;

        var seconds = Math.floor(expiresIn);

        minutes = pretty_time_string(minutes);
        seconds = pretty_time_string(seconds);
        var currentTimeString = minutes + ":" + seconds;

        $(elemId).text(currentTimeString);
    }
}

$(document).on('mouseover', '.info-circle', function(e) {
    $(this).find('.tooltip').show();
});
$(document).on('mouseout', '.info-circle', function(e) {
    $(this).find('.tooltip').hide();
});
$(document).on('click', '.upload-with-loader', function(e) {
    e.preventDefault();
    if(this.form.reportValidity()){
        $(this).prop('disabled', true);
        this.form.submit();
        loader_show();
    }
});
$(document).on('click', '.click-with-change', function(e) {
    e.preventDefault();
    if(this.form.reportValidity()){
        $(this).prop('disabled', true)
               .prop('value', $(this).data('wait-text'))
               .toggleClass('grey');
        this.form.submit();
    }
});
$(document).on('click', '.click-with-spinner', function(e) {
    spinner_show();
});

$(document).on('click', '.add_dynamic_field', function(e) {
    e.preventDefault();
    let target_id = $(this).data('target');
    let template = $('#' + target_id + ' .template-form-field').html();
    let timestamp = new Date().getTime() + Math.floor((Math.random() * 100) + 1);
    template = template.replace(/_000/, '_' + timestamp);
    template = template.replace(/ disabled="disabled"/, '')
    $('#' + target_id).append(template);

    let count = $('#' + target_id + ' .multiple-dynamic-field').length;
    if (count > 10) {
        $(this).remove();
        return;
    }
});

$(document).ready(function() {
    if ( $("input.autonumeric").length ) {
        AutoNumeric.multiple('input.autonumeric', { digitGroupSeparator: ' ', decimalCharacter: ',', decimalPlaces: 0, minimumValue: 0 });
    }
    if ( $("input.autonumeric-btc").length ) {
        AutoNumeric.multiple('input.autonumeric-btc', { digitGroupSeparator: ' ', decimalCharacter: ',', decimalPlaces: 8, minimumValue: 0 });
    }
    if ( $("input.autonumeric-sat").length ) {
        AutoNumeric.multiple('input.autonumeric-sat', { digitGroupSeparator: ' ', decimalCharacter: ',', decimalPlaces: 0, minimumValue: 0 });
    }
    //$(document).trigger('refresh_autonumeric');

    $('.add_dynamic_field').click(event)
});

$(document).ready(function() {
  $(function() {
    $('.jqui-datepicker').datepicker({
      dateFormat: 'dd.mm.yy',
      showButtonPanel: false,
      changeMonth: true,
      changeYear: true,
      minDate: '+1D',
      maxDate: '+30Y',
      inline: true
    });
  });
  $.datepicker.regional['cs'] = {
      closeText: 'Zavřít',
      prevText: 'Předchozí',
      nextText: 'Další',
      currentText: 'Dnes',
      monthNames: ['leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'],
      monthNamesShort: ['leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'],
      dayNames: ['neděle', 'pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota'],
      dayNamesShort: ['ne', 'po', 'út', 'st', 'čt', 'pá', 'so'],
      dayNamesMin: ['Ne','Po','Út','St','Čt','Po','So'],
      weekHeader: 'Týd.',
      dateFormat: 'dd.mm.yy',
      firstDay: 1,
      isRTL: false,
      showMonthAfterYear: false,
      yearSuffix: ''
  };
  $.datepicker.setDefaults($.datepicker.regional['cs']);
});
